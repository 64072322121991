var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c("b-col", { attrs: { cols: "12" } }, [_c("modal-basic")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("modal-theme")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("modal-sizes")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("modal-form-scroll")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("modal-variant")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("modal-vmodal")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("modal-prevent")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("modal-disbale-foooter")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("modal-message-box")], 1),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [_c("modal-footer-button-sizing")],
        1
      ),
      _c("b-col", { attrs: { md: "6" } }, [_c("modal-multiple-support")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("modal-method")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }