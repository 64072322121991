var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Variants" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeVariant) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "Control the header, footer, and body background and text variants by setting the "
          )
        ]),
        _c("code", [
          _vm._v(
            "header-bg-variant, header-text-variant, body-bg-variant, body-text-variant, footer-bg-variant, and footer-text-variant"
          )
        ]),
        _c("span", [_vm._v(" props.")])
      ]),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(113, 102, 240, 0.15)",
              expression: "'rgba(113, 102, 240, 0.15)'",
              modifiers: { "400": true }
            }
          ],
          attrs: { variant: "outline-primary" },
          on: {
            click: function($event) {
              _vm.show = true
            }
          }
        },
        [_vm._v(" Show Modal ")]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Modal Variants",
            "title-tag": "div",
            "header-bg-variant": _vm.headerBgVariant,
            "header-text-variant": _vm.headerTextVariant,
            "body-bg-variant": _vm.bodyBgVariant,
            "body-text-variant": _vm.bodyTextVariant,
            "footer-bg-variant": _vm.footerBgVariant,
            "footer-text-variant": _vm.footerTextVariant
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100 d-flex justify-content-between" },
                    [
                      _c("b-card-text", { staticClass: "mb-0" }, [
                        _vm._v(" Modal Footer Content ")
                      ]),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(255, 255, 255, 0.15)",
                              expression: "'rgba(255, 255, 255, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          attrs: { variant: "secondary", size: "sm" },
                          on: {
                            click: function($event) {
                              _vm.show = false
                            }
                          }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                { staticClass: "mb-1 text-center" },
                [
                  _c("b-col", { attrs: { cols: "3" } }),
                  _c("b-col", [_vm._v("Background")]),
                  _c("b-col", [_vm._v("Text")])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c("b-col", { attrs: { cols: "3" } }, [_vm._v(" Header ")]),
                  _c(
                    "b-col",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.variants },
                        model: {
                          value: _vm.headerBgVariant,
                          callback: function($$v) {
                            _vm.headerBgVariant = $$v
                          },
                          expression: "headerBgVariant"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.variants },
                        model: {
                          value: _vm.headerTextVariant,
                          callback: function($$v) {
                            _vm.headerTextVariant = $$v
                          },
                          expression: "headerTextVariant"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c("b-col", { attrs: { cols: "3" } }, [_vm._v(" Body ")]),
                  _c(
                    "b-col",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.variants },
                        model: {
                          value: _vm.bodyBgVariant,
                          callback: function($$v) {
                            _vm.bodyBgVariant = $$v
                          },
                          expression: "bodyBgVariant"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.variants },
                        model: {
                          value: _vm.bodyTextVariant,
                          callback: function($$v) {
                            _vm.bodyTextVariant = $$v
                          },
                          expression: "bodyTextVariant"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "3" } }, [_vm._v(" Footer ")]),
                  _c(
                    "b-col",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.variants },
                        model: {
                          value: _vm.footerBgVariant,
                          callback: function($$v) {
                            _vm.footerBgVariant = $$v
                          },
                          expression: "footerBgVariant"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.variants },
                        model: {
                          value: _vm.footerTextVariant,
                          callback: function($$v) {
                            _vm.footerTextVariant = $$v
                          },
                          expression: "footerTextVariant"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }