var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Modal Sizes" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v("Modals have three optional sizes, available via the prop ")
        ]),
        _c("code", [_vm._v("size")]),
        _c("span", [
          _vm._v(
            " . These sizes kick in at certain breakpoints to avoid horizontal scrollbars on narrower viewports. Valid optional sizes are "
          )
        ]),
        _c("code", [_vm._v("xs,sm, lg and xl")]),
        _vm._v(". ")
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-xs",
                  modifiers: { "modal-xs": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Extra Small ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-sm",
                  modifiers: { "modal-sm": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Small Modal ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-default",
                  modifiers: { "modal-default": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Default Modal ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-lg",
                  modifiers: { "modal-lg": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Large Modal ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-xl",
                  modifiers: { "modal-xl": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Extra Large Modal ")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-xs",
            "cancel-variant": "secondary",
            "ok-only": "",
            "ok-title": "Accept",
            centered: "",
            size: "xs",
            title: "Extra Small Modal"
          }
        },
        [
          _c("b-card-text", [
            _vm._v(
              "Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly."
            )
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-sm",
            "cancel-variant": "secondary",
            "ok-only": "",
            "ok-title": "Accept",
            centered: "",
            size: "sm",
            title: "Small Modal"
          }
        },
        [
          _c("b-card-text", [
            _vm._v(
              "Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly."
            )
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-default",
            "ok-only": "",
            "ok-title": "Accept",
            centered: "",
            title: "Default Modal"
          }
        },
        [
          _c("b-card-text", [
            _vm._v(
              "Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly."
            )
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-lg",
            "ok-only": "",
            "ok-title": "Accept",
            centered: "",
            size: "lg",
            title: "Large Modal"
          }
        },
        [
          _c("b-card-text", [
            _vm._v(
              "Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly."
            )
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-xl",
            "ok-only": "",
            "ok-title": "Accept",
            centered: "",
            size: "xl",
            title: "Extra Large Modal"
          }
        },
        [
          _c("b-card-text", [
            _vm._v(
              "Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly."
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }