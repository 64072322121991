var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Confirm message box" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeMessageBox) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "BootstrapVue provides a few built in Message Box methods on the exposed "
          )
        ]),
        _c("code", [_vm._v("this.$bvModal")]),
        _c("span", [
          _vm._v(
            " object. These methods provide a way to generate simple OK and Confirm style modal messages, from anywhere in your app without having to explicitly place a "
          )
        ]),
        _c("code", [_vm._v("<b-modal>")]),
        _c("span", [_vm._v(" component in your pages.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: { click: _vm.showMsgBoxOne }
            },
            [_vm._v(" Simple msgBoxConfirm ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: { click: _vm.showMsgBoxTwo }
            },
            [_vm._v(" msgBoxConfirm with options ")]
          )
        ],
        1
      ),
      _c("b-card-text", { staticClass: "mt-2" }, [
        _vm._v(" First modal return value: "),
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v(_vm._s(String(_vm.boxOne)))
        ])
      ]),
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(" Second modal return value: "),
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v(_vm._s(String(_vm.boxTwo)))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }