var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Using v-model property" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeVmodal) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("code", [_vm._v("v-model")]),
        _c("span", [_vm._v(" property is always automatically synced with ")]),
        _c("code", [_vm._v("<b-modal>")]),
        _c("span", [_vm._v(" visible state and you can show/hide using ")]),
        _c("code", [_vm._v("v-model")]),
        _vm._v(". ")
      ]),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(113, 102, 240, 0.15)",
              expression: "'rgba(113, 102, 240, 0.15)'",
              modifiers: { "400": true }
            }
          ],
          attrs: { variant: "outline-primary" },
          on: {
            click: function($event) {
              _vm.modalShow = !_vm.modalShow
            }
          }
        },
        [_vm._v(" Launch Modal ")]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Using v-model property",
            "ok-title": "Accept",
            "ok-only": ""
          },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c("b-card-text", [
            _vm._v(
              " Bonbon caramels muffin. Chocolate bar oat cake cookie pastry dragée pastry. Carrot cake chocolate tootsie roll chocolate bar candy canes biscuit. Gummies bonbon apple pie fruitcake icing biscuit apple pie jelly-o sweet roll. Toffee sugar plum sugar plum jelly-o jujubes bonbon dessert carrot cake. Cookie dessert tart muffin topping donut icing fruitcake. Sweet roll cotton candy dragée danish Candy canes chocolate bar cookie. Gingerbread apple pie oat cake. Carrot cake fruitcake bear claw. Pastry gummi bears marshmallow jelly-o. "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }