var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Basic Modal" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Use the prop ")]),
        _c("code", [_vm._v("no-fade")]),
        _c("span", [_vm._v(" on the ")]),
        _c("code", [_vm._v("<b-modal>")]),
        _c("span", [_vm._v(" component to disable animation. ")]),
        _c("span", [
          _vm._v("Vertically center your modal in the viewport by setting the ")
        ]),
        _c("code", [_vm._v("centered")]),
        _c("span", [_vm._v(" prop.")]),
        _c("span", [_vm._v(" Hide the modal's backdrop via setting the ")]),
        _c("code", [_vm._v("hide-backdrop")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-1",
                  modifiers: { "modal-1": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Basic Modal ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-center",
                  modifiers: { "modal-center": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Vertically Center ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-no-backdrop",
                  modifiers: { "modal-no-backdrop": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Disabled Backdrop ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-no-animation",
                  modifiers: { "modal-no-animation": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Disabled Animation ")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-1",
            title: "Basic Modal",
            "ok-only": "",
            "ok-title": "Accept"
          }
        },
        [
          _c("b-card-text", [
            _c("h5", [_vm._v("Check First Paragraph")]),
            _vm._v(
              " Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie. Brownie carrot cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake liquorice. "
            )
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-no-animation",
            "content-class": "shadow",
            title: "Disabled Animation",
            "no-fade": "",
            "ok-only": "",
            "ok-title": "Accept"
          }
        },
        [
          _c("b-card-text", [
            _vm._v(
              " Chocolate bar jelly dragée cupcake chocolate bar I love donut liquorice. Powder I love marzipan donut candy canes jelly-o. Dragée liquorice apple pie candy biscuit danish lemon drops sugar plum. "
            )
          ]),
          _c("b-alert", { attrs: { show: "", variant: "success" } }, [
            _c("div", { staticClass: "alert-body" }, [
              _vm._v(
                " Well done! You successfully read this important alert message. "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-center",
            centered: "",
            title: "Vertically Centered",
            "ok-only": "",
            "ok-title": "Accept"
          }
        },
        [
          _c("b-card-text", [
            _vm._v(
              " Croissant jelly-o halvah chocolate sesame snaps. Brownie caramels candy canes chocolate cake marshmallow icing lollipop I love. Gummies macaroon donut caramels biscuit topping danish. "
            )
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-no-backdrop",
            "hide-backdrop": "",
            "ok-only": "",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            title: "Disabled Backdrop",
            "ok-title": "Accept"
          }
        },
        [
          _c("b-card-text", [
            _c("span", [_vm._v("We've added the utility class")]),
            _c("code", [_vm._v("'shadow'")]),
            _c("span", [_vm._v("to the modal content for added effect.")])
          ]),
          _c("b-card-text", [
            _vm._v(
              " Candy oat cake topping topping chocolate cake. Icing pudding jelly beans I love chocolate carrot cake wafer candy canes. Biscuit croissant fruitcake bonbon soufflé. "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }