var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Form & Scrolling Modals" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeFormScroll) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-login",
                  modifiers: { "modal-login": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Login Form ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-tall",
                  modifiers: { "modal-tall": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Scrolling Long Content ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-scrollable",
                  modifiers: { "modal-scrollable": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Scrolling Content inside Modal ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-select2",
                  modifiers: { "modal-select2": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Select2 With Modal ")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-login",
            "cancel-variant": "outline-secondary",
            "ok-title": "Login",
            "cancel-title": "Close",
            centered: "",
            title: "Login Form"
          }
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-form-group",
                [
                  _c("label", { attrs: { for: "email" } }, [_vm._v("Email:")]),
                  _c("b-form-input", {
                    attrs: {
                      id: "email",
                      type: "email",
                      placeholder: "Email Address"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                [
                  _c("label", { attrs: { for: "password" } }, [
                    _vm._v("Password")
                  ]),
                  _c("b-form-input", {
                    attrs: { type: "password", placeholder: "Password" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-tall",
            title: "Overflowing Content",
            "cancel-variant": "outline-secondary",
            "cancel-title": "Close",
            "ok-title": "Accept"
          }
        },
        _vm._l(_vm.scrollContent, function(content, index) {
          return _c("b-card-text", { key: index }, [
            _vm._v(" " + _vm._s(content) + " ")
          ])
        }),
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-scrollable",
            scrollable: "",
            title: "Scrollable Content",
            "cancel-title": "Close",
            "ok-title": "Accept",
            "cancel-variant": "outline-secondary"
          }
        },
        _vm._l(_vm.scrollContent, function(content, index) {
          return _c("b-card-text", { key: index }, [
            _vm._v(" " + _vm._s(content) + " ")
          ])
        }),
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-select2",
            title: "Basic Modal",
            "ok-title": "submit",
            "cancel-variant": "outline-secondary"
          }
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Enter Name", "label-for": "name" } },
                [
                  _c("b-form-input", {
                    attrs: { id: "name", placeholder: "Enter name" }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Choose the country",
                    "label-for": "vue-select"
                  }
                },
                [
                  _c("v-select", {
                    attrs: {
                      id: "vue-select",
                      dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                      options: _vm.option
                    },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "Zip Code", "label-for": "zip-code" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "zip-code",
                      type: "number",
                      placeholder: "Zip Code"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }