var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Modal Method" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeMethod) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("You can access modal using ")]),
        _c("code", [_vm._v("ref")]),
        _c("span", [_vm._v(" attribute and then call the ")]),
        _c("code", [_vm._v("show(), hide()")]),
        _c("span", [_vm._v(" or ")]),
        _c("code", [_vm._v("toggle()")]),
        _c("span", [_vm._v(" methods.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "show-btn", variant: "outline-primary" },
              on: { click: _vm.showModal }
            },
            [_vm._v(" Open Modal ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "toggle-btn", variant: "outline-primary" },
              on: { click: _vm.toggleModal }
            },
            [_vm._v(" Toggle Modal ")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: { "hide-footer": "", title: "Using Component Methods" }
        },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c("h3", [_vm._v("Hello From My Modal!")])
          ]),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "mt-3",
              attrs: { variant: "outline-secondary", block: "" },
              on: { click: _vm.hideModal }
            },
            [_vm._v(" Close Me ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "mt-2",
              attrs: { variant: "outline-primary", block: "" },
              on: { click: _vm.toggleModal }
            },
            [_vm._v(" Toggle Me ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }